import "./styles.css";
import profilepic from "./images/Ben-Profile.jpg";
let currentYear = new Date().getFullYear()

export default function App() {
  return (
    <div className="App">
      <div className="Nav">
        <div className="Button">
          <a href="mailto:contact@benmoffatt.co.uk">Contact</a>
        </div>
      </div>

      <div className="Name">
        <div className="NameTitle">
          <div className="NameTitleName">Ben Moffatt</div>
          <div className="NameTitleRole">Interaction Designer</div>
        </div>
      </div>

      <div className="Profile">
        <div className="ProfilePic">
          <img src={profilepic} width="100%" height="100%" />
        </div>
        <div className="ProfileInfo">
          <h5>
            <a
              href="https://www.rca.ac.uk/schools/school-of-design/innovation-design-engineering/"
              target="_blank"
            >
              MA + MSc INNOVATION DESIGN ENGINEERING
            </a>
          </h5>

          <h6>Royal College of Art</h6>
          <h5>
            <a
              href="http://www.imperial.ac.uk/mechanical-engineering"
              target="_blank"
            >
              MEng MECHANICAL ENGINEERING
            </a>
          </h5>
          <h6>Imperial College London</h6>
          <p>
            Interaction designer interested in how small scale innovations can
            create widespread societal benefit.
          </p>
          <p>
            Experienced interaction design leader specialising in strategy,
            research and prototyping. Adept at building high performing, multi
            disciplinary teams that combine physical and digital technologies to
            beneficially transform lives in ways people can’t even imagine
            today.{" "}
          </p>
          <p>Let's solve big problems together and make stuff real. </p>
        </div>
      </div>

      <div className="footer">
        <div className="footerText">© {currentYear} Ben Moffatt</div>
      </div>
    </div>
  );
}
